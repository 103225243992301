//---------- config ----------
var SITE = {};
SITE.config = window.SITE_config || {};
SITE.config.devMode = SITE.config.devMode || false;
SITE.config.isProduction = SITE.config.isProduction || false;
SITE.config.baseUrl = SITE.config.baseUrl || window.location.protocol + '//' + window.location.hostname;
SITE.config.scrollSpeed = 500;
SITE.vueApps = [];
SITE.breakpointMobile = 768;
SITE.transitionEnd = 'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend';

//---------- helpers ----------
SITE.helpers = {
	/**
	 * Determines if a div is scrolled into view.
	 * from: http://stackoverflow.com/a/488073/1136822
	 *
	 * @param elem
	 * @returns {boolean}
	 */
	isScrolledIntoView : function(elem)
	{
		var docViewTop = $(window).scrollTop();
		var docViewBottom = docViewTop + $(window).height();

		var elemTop = $(elem).offset().top;
		var elemBottom = elemTop + $(elem).height();

		return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
	},
	/**
	 * Useful utility to determine the browser's scrollbar width.
	 * from: https://davidwalsh.name/detect-scrollbar-width
	 */
	getScrollbarWidth : function() {
		// Create the measurement node
		var scrollDiv = document.createElement("div");
		scrollDiv.className = "scrollbar-measure";
		document.body.appendChild(scrollDiv);

		// Get the scrollbar width
		var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

		// Delete the DIV
		document.body.removeChild(scrollDiv);

		return scrollbarWidth;
	}
};

//---------- components ----------
/**
 * SITE components.
 * @type {{init: Function}}
 */
SITE.components = {
	alreadyInitialized: false,
	/**
	 * Smooth scroll to anchors.
	 */
	smoothScroll: function () {
		var currentPage = window.location.protocol + '//' + window.location.hostname + window.location.pathname;
		var currentPageRelative = window.location.pathname;

		$('a[href^="#"], [href^="' + currentPage + '#"], [href^="' + currentPageRelative + '#"]').smoothScroll({
			speed: SITE.config.scrollSpeed,
			preventDefault: true
		});
	},
	/**
	 * Magnific lightboxes.
	 */
	lightboxes: function () {
		//----------------- magnific popup videos
		var magnificVideosSelector = "a[href*='youtu.be'], a[href*='youtube.com/watch'], a[href*='youtube.com/embed'], a[href*='vimeo.com']";
		var magnificVideoOptions = {
			type: 'iframe',
			closeOnContentClick: false,
			closeOnBgClick: true,
			callbacks: {
				open: function () {
					if (SITE.mobileMenu) {
						SITE.mobileMenu.close();
					}
				},
				markupParse: function (template, values, item) {
					values.title = $(item.el).attr('data-caption');
				}
			},
			iframe: {
				patterns: {
					youtube_short: {
						index: 'youtu.be/',
						id: 'youtu.be/',
						src: '//www.youtube.com/embed/%id%?autoplay=1&rel=0'
					},
					youtube_embeds: {
						index: 'youtube.com/embed',
						id: 'embed/',
						src: '//www.youtube.com/embed/%id%?autoplay=1&rel=0'
					},
					youtube: {
						index: 'youtube.com/watch',
						id: 'v=',
						src: '//www.youtube.com/embed/%id%?autoplay=1&rel=0'
					},
					vimeo: {
						index: 'vimeo.com/',
						id: function (src) {
							if (src.indexOf('external') > -1) {
								return 'external/' + src.substr(src.lastIndexOf('/') + 1, src.length);
							} else {
								return 'video/' + src.substr(src.lastIndexOf('/') + 1, src.length);
							}
						},
						src: '//player.vimeo.com/%id%?autoplay=1'
					}
				}
			},
			gallery: {
				enabled: false
			}
		};
		$(magnificVideosSelector).magnificPopup(magnificVideoOptions);

		//----------------- magnific popup images
		var magnificImagesSelector = 'a[href$=".gif"], a[href$=".jpg"], a[href$=".png"]';
		var magnificIgnoreSelector = '.no-lightbox';
		var magnificImageOptions = {
			type: 'image',
			closeOnContentClick: false,
			closeOnBgClick: true,
			callbacks: {
				open: function () {
					if (SITE.mobileMenu) {
						SITE.mobileMenu.close();
					}
				},
				markupParse: function (template, values, item) {
					values.title = $(item.el).attr('data-caption');
				}
			},
			gallery: {
				enabled: false
			},
			image: {
				titleSrc: 'data-caption'
			},
			mainClass: 'mfp-with-zoom mfp-fade',
			removalDelay: SITE.cssTransitionTimeMs,
			zoom: {
				enabled: true,
				duration: 300,
				easing: 'ease-in-out',
				opener: function (openerElement) {
					if (openerElement.is('img')) {
						return openerElement;
					} else {
						var $img = openerElement.find('img');
						if ($img.length) {
							if (!!$img.attr('src')) {
								return $img;
							}
						}
					}

					return openerElement;
				}
			}
		};
		$(magnificImagesSelector).filter(':not(' + magnificIgnoreSelector + ')').magnificPopup(magnificImageOptions).addClass('zoom-in');

		//----------------- magnific popup mixed galleries
		//combine the video and image settings
		var magnificGalleryOptions = $.extend({}, magnificVideoOptions, magnificImageOptions);
		//add callback to dynamically determine the type
		magnificGalleryOptions.callbacks.elementParse = function (item) {
			if ($(item.el).is(magnificVideosSelector)) {
				item.type = 'iframe';
			} else {
				item.type = 'image';
			}
		};

		//enable gallery mode
		magnificGalleryOptions.gallery = {
			enabled: true,
			navigateByImgClick: true,

			arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>', // markup of an arrow button

			tPrev: 'Previous (Left arrow key)', // title for left button
			tNext: 'Next (Right arrow key)', // title for right button
			tCounter: '<span class="mfp-counter">%curr% of %total%</span>' // markup of counter
		};
		//disable zoom so that videos won't throw an error
		//magnificGalleryOptions.zoom = { enabled: false };
		magnificGalleryOptions.mainClass = 'mfp-fade';

		$('[data-gallery]').each(function () {
			$(this).find('[data-gallery-item]').magnificPopup(magnificGalleryOptions);
		});
	},
	/**
	 * Lightboxes inline.
	 */
	lightboxesInline: function () {
		$('[data-lightbox-inline]').magnificPopup({
			type: 'inline',
			midClick: true,
			mainClass: 'mfp-fade',
			removalDelay: SITE.cssTransitionTimeMs
		});
	},
	/**
	 * Slider.
	 */
	slider: function () {
		$('#pjax-container').find('[data-slider]').each(function (ind, slider) {
			var $slider = $(slider);
			//default options (masthead, bg)
			var options = {
				autoplay: true,
				autoplaySpeed: 4000,
				pauseOnHover: false,
				arrows: false,
				dots: false,
				swipe: false,
				infinite: true,
				speed: 2000,
				fade: true,
				adaptiveHeight: false
			};

			var sliderType = $slider.attr('data-slider');

			if (sliderType) {
				switch (sliderType) {
					case 'inline':
						options = {
							autoplay: true,
							autoplaySpeed: 4000,
							pauseOnHover: true,
							arrows: false,
							dots: true,
							swipe: true,
							infinite: true,
							speed: 2000,
							fade: true,
							adaptiveHeight: false
						};
						break;
					case 'carousel-primary':
						var $sub = $slider.siblings('[data-slider="carousel-sub"]').first();
						var subClass = 'carouselSub'+ind;
						if ($sub.length) {
							$sub.addClass(subClass);
						}
						options = {
							autoplay: false,
							autoplaySpeed: 4000,
							pauseOnHover: true,
							arrows: false,
							dots: true,
							swipe: true,
							infinite: true,
							speed: 2000,
							fade: true,
							adaptiveHeight: false,
							asNavFor: $sub.length ? '.'+subClass : null,
							waitForAnimate: false
						};
						break;
					case 'carousel-sub':
						var $primary = $slider.siblings('[data-slider="carousel-primary"]').first();
						var primaryClass = 'carouselPrimary'+ind;
						if ($primary.length) {
							$primary.addClass(primaryClass);
						}
						options = {
							autoplay: false,
							pauseOnHover: true,
							arrows: false,
							dots: false,
							swipe: false,
							infinite: true,
							speed: 2000,
							fade: true,
							adaptiveHeight: false,
							centerMode: true,
							asNavFor: $primary.length ? '.'+primaryClass : null,
							waitForAnimate: false,
							centerPadding: '0px'
						};
						break;
				}
			}

			if (!$slider.hasClass('slick-initialized')) {
				$slider.slick(options);

				setTimeout(function () {
					$slider.find('.slide').removeClass('lazyloadWithFade');
				}, 500);
			}
		});
	},
	/**
	 * Mobile menu.
	 */
	mobileMenu: function () {
		var $html = $('html').first(),
			$body = $('body').first(),
			mobileMenuIsOpen = false,
			openedClass = 'mobileMenuOpen',
			$mobileMenu = $('[data-mobile-menu]').first();

		var isHomepage = $html.hasClass('homepage');

		if ($mobileMenu.length) {
			SITE.mobileMenu = new MobileMenu(false, {
				onOpen: function (el) {
					$html.addClass(openedClass);
					$.publish('mobileMenu.opened');

					$mobileMenu
						.addClass('mobileMenu-block')
						.outerWidth();

					$mobileMenu
						.addClass('mobileMenu-in');
				},
				onClose: function (el) {
					$html.removeClass(openedClass);
					$.publish('mobileMenu.closed');

					$mobileMenu
						.removeClass('mobileMenu-in')
						.one(SITE.transitionEnd, function(){
							if (!mobileMenuIsOpen) {
								$mobileMenu
									.outerWidth();
								$mobileMenu
									.removeClass('mobileMenu-block');

								//fixes a browser bug where the browser sometimes leaves
								//a gap between the html and body after changing the
								//html and body overflows
								$(window).trigger('resize');
								$mobileMenu
									.outerWidth();
								$(window).trigger('resize');
							}
						});
					}
			});

			//open button
			var $mobileMenuToggle = $('[data-mobileMenuToggle]');
			$mobileMenuToggle.click(function (e) {
				if (! isHomepage) {
					if (mobileMenuIsOpen) {
						SITE.mobileMenu.close();
					} else {
						SITE.mobileMenu.open();
					}
				}
				mobileMenuIsOpen = !mobileMenuIsOpen;
			});

			//close if a link is clicked
			/*$header.on('click', 'a', function (e) {
				SITE.mobileMenu.close();
			});*/
		}
	},
	/**
	 * Responsive videos.
	 */
	responsiveVideos: function () {
		//based on http://css-tricks.com/NetMag/FluidWidthVideo/demo.php
		var $allVideos = $("iframe[src*='//player.vimeo.com'], iframe[src*='//www.youtube.com'], object, embed").not('[data-video-unresponsive]');
		$allVideos.each(function () {
			$(this)
			// jQuery .data does not work on object/embed elements
				.attr('data-aspectRatio', $(this).height() / $(this).width())
				.removeAttr('height')
				.removeAttr('width');

			if (!$(this).parent('.video-player').length) {
				$(this).wrap('<div class="video-player">');
			}
		});
		function resizeVideos() {
			$allVideos.each(function () {
				var $el = $(this),
					$parentEl = $el.parent('.video-player');
				if ($parentEl.length) {
					var newWidth = $parentEl.width();
					var newHeight = newWidth * $el.attr('data-aspectRatio');
					$el
						.width(Math.round(newWidth))
						.height(Math.round(newHeight));
				}
			});
		}

		$(window).on('DOMContentLoaded load resize', debounce(resizeVideos, 100, false));
		resizeVideos();
	},
	/**
	 * Sets up the simple maps.
	 */
	simpleMaps: function() {
		var $maps = $('[data-map]');
		if ($maps.length) {
			var key = $maps.first().attr('data-key');

			window.initGMaps = function() {
				$maps.each(function(ind,el){
					var $el = $(el);
					var lat = parseFloat($el.attr('data-lat'));
					var lng = parseFloat($el.attr('data-lng'));
					var zoom = parseInt($el.attr('data-zoom'), 10);
					var markerName =$el.attr('data-marker-name');
					var markerIcon = $el.attr('data-marker-icon');
					var markerWidth = $el.attr('data-marker-icon-width');
					var markerHeight = $el.attr('data-marker-icon-height');
					var styleVar = $el.attr('data-styles-var');
					var latLng = {lat:lat, lng:lng};
					var mapOptions = {
						center: latLng,
						zoom: zoom,
						scrollwheel: false,
						disableDoubleClickZoom: true,
						panControl: false,
						streetViewControl: false,
						mapTypeControl: false,
						zoomControl: true,
						scaleControl: false,
						rotateControl: false,
						fullscreenControl: false,
						zoomControlOptions: {
							position: google.maps.ControlPosition.LEFT_BOTTOM,
						},
					};

					//add the styles if applicable
					if (styleVar && window[styleVar]) {
						mapOptions.styles = window[styleVar];
					}

					map = new google.maps.Map(el, mapOptions);

					var markerOptions = {position:latLng, map:map};
					if (markerIcon) {
						markerOptions.icon = {
							url: markerIcon,
							size: new google.maps.Size(markerWidth, markerHeight),
							origin: new google.maps.Point(0, 0),
							anchor: new google.maps.Point(markerWidth*0.5, markerHeight*0.5),
							scaledSize: new google.maps.Size(markerWidth, markerHeight)
						};
					}
					if (markerName) {
						markerOptions.place = {
							location: latLng,
							query: markerName
						};
					}

					var marker = new google.maps.Marker(markerOptions);
					if (markerName) {
						var infoWindow = new google.maps.InfoWindow({content:'<h1>'+markerName+'</h1>'});
						marker.addListener('click', function() {
							infoWindow.open(map, marker);
						});
						// new google.maps.event.trigger(marker, 'click');
					}

					// listen for the window resize event & trigger Google Maps to update too
					window.onresize = function() {
						var currentCenter = map.getCenter();
						google.maps.event.trigger(map, 'resize');
						map.setCenter(currentCenter);
					};
				});
			};

			head.load({ gMaps: "https://maps.googleapis.com/maps/api/js?key="+key+"&callback=initGMaps" });
		}
	},
	/**
	 * Loads the Vimeo API.
	 */
	vimeoApiLoader: function () {
		head.load({ VimeoApi: "https://player.vimeo.com/api/player.js" });
	},
	/**
	 * Loads the YouTube API and sets up the background videos.
	 */
	vimeoBackgroundVideo: function () {
		var $allVideos = $("iframe[src*='vimeo.com'][data-video-background]");

		//if we have videos, load the api
		if ($allVideos.length) {
			SITE.components.vimeoApiLoader();

			//called when the api is loaded
			head.ready(['VimeoApi'], function () {
				$allVideos.each(function(ind,el){
					var player = new Vimeo.Player(el);
					player.setVolume(0);

					var $parentWrapper = $(el).closest('[data-video-holder]');
					if ($parentWrapper.length) {
						if (!head.mobile && !head.touch)
						{
							$parentWrapper
								.addClass('videoImages-mask')
								.click(function(ce){
									ce.preventDefault();
									player.play();
								});
						}
					}
				});
			});
		}
	},
	/**
	 * Loads the YouTube API.
	 */
	youTubeApiLoader: function () {
		head.load({ YouTubeApi: "https://www.youtube.com/iframe_api" });

		//YouTube api called
		window.onYouTubeIframeAPIReady = function() {
			$.publish('youTubeApiReady');
		}
	},
	/**
	 * Loads the YouTube API and autoplays the video when it scrolls into view.
	 */
	youTubeAutoPlayOnScroll: function () {
		var $allVideos = $("iframe[src*='//www.youtube.com'][data-video-auto-play-on-scroll]");

		//watches the video
		var watchVideoPos = function(el, player) {
			var triggered = false;
			$(window).scroll(function(){
				//plays the video the first time it's scrolled into view
				if (! triggered && SITE.helpers.isScrolledIntoView(el)){
					triggered = true;
					player.playVideo();
				}
			});
		};

		//if we have videos, load the api
		if ($allVideos.length) {
			SITE.components.youTubeApiLoader();

			//called when the api is loaded
			$.subscribe('youTubeApiReady', function() {
				$allVideos.each(function(ind,el){
					var player = new YT.Player(el, {
						events: {
							onReady: function(e) {
								watchVideoPos(el,e.target);
							}
						}
					});
				});
			});
		}
	},
	/**
	 * Loads the YouTube API and sets up the background videos.
	 */
	youTubeBackgroundVideo: function () {
		var $allVideos = $("iframe[src*='//www.youtube.com'][data-video-background]");

		//if we have videos, load the api
		if ($allVideos.length) {
			SITE.components.youTubeApiLoader();

			//called when the api is loaded
			$.subscribe('youTubeApiReady', function() {
				$allVideos.each(function(ind,el){


					new YT.Player(el, {
						playerVars: {
							autoplay: 1,
							controls: 0,
							showinfo: 0,
							loop: 1,
							fs: 0,
							cc_load_policy: 0,
							iv_load_policy: 3,
							modestbranding: 1,
							rel: 0
						},
						events: {
							onReady: function(e) {
								e.target.mute();

								//parent holder click handler
								var $parentWrapper = $(el).closest('[data-video-holder]');
								if ($parentWrapper.length) {
									if (!head.mobile && !head.touch)
									{
										$parentWrapper
											.addClass('videoImages-mask')
											.click(function(ce){
												ce.preventDefault();

												//play the video
												if (e.target.getPlayerState() !== 1) {
													e.target.playVideo();
												}
											});
									}
								}
							}
						}
					});
				});
			});
		}
	},
	/**
	 * The nav app.
	 */
	navApp: function () {

		var navApp = function(app){
			//app element
			var $app = $(app);

			//parent elements
			var $window = $(window),
				$html = $('html').eq(0);

			//child elements
			var $menu = $app.find('.navApp-menu');
			var $menuLinks = $menu.find(':not(.pastDivider) a');
			var $menuPastDividerLinks = $menu.find('.pastDivider');
			var $introText = $app.find('.navApp-introText');
			var $introTextLinks = $introText.find('a');
			var $introTextLines = $introText.find('.navApp-introText-line');
			var introTextLinksCount = $introTextLinks.length;

			//variable flags
			var isTextAligning = false;

			/**
			 * Sets up the alternative titles for the nav items.
			 *
			 * @param $items
			 */
			var setUpAltTitles = function($items) {
				$.each($items, function(ind,item){
					var $item = $(item);
					var altTitle = $item.attr('data-alt-title');
					if (altTitle) {
						var $altTitleSpan = $('<span class="navApp-altTitle"></span>');
						var $altTitleInnerSpan = $('<span class="navApp-altTitleInner">'+altTitle+'</span>');
						$item.find('span').append($altTitleSpan);
						$altTitleSpan.append($altTitleInnerSpan);
						//set up the transition delay
						$altTitleInnerSpan.css('transition-delay', (ind*100)+'ms');
					}
				})
			};

			/**
			 * Sets up CSS transition staggering.
			 *
			 * @param {array} $items
			 * @param {int} offset The stagger offset. Defaults to the number zero.
			 */
			var setUpStaggering = function($items, offset) {
				if (typeof offset === 'undefined') {
					offset = 0;
				}
				$.each($items, function(ind,item){
					$(item).css('transition-delay', ((ind+offset)*100)+'ms');
				})
			};

			/**
			 * Aligns the text with the menu.
			 */
			var alignFirstLinksVertically = function () {
				$introText.css({display:'block',top:0});
				if ($menuLinks.length && $introTextLinks.length) {
					var firstMenuLinkVerticalOffset = $menuLinks.eq(0).offset().top;
					var firstIntroLinkVerticalOffset = $introTextLinks.eq(0).offset().top;
					$introText.css('top', firstMenuLinkVerticalOffset - firstIntroLinkVerticalOffset);
				}
			};

			/**
			 * Aligns text vertically as needed.
			 */
			var alignText = function() {
				if (isTextAligning) {
					if ( head.screen.innerWidth >= SITE.breakpointMobile) {
						alignFirstLinksVertically();
					} else {
						$introText.css('display','none');
					}
				}
			};

			/**
			 * Align the links
			 */
			var alignLinksHorizontally = function() {
				$.each($menuLinks, function(ind){
					if (ind > introTextLinksCount - 1) {
						return;
					}
					var $menuLink = $(this);
					$menuLink.css('left','');
					var menuLinkHorizontalOffset = $menuLink.offset().left;
					var menuLinkLeftPadding = parseInt($('span:first', $menuLink).css('paddingLeft'), 10);
					var introLinkHorizontalOffset = $introTextLinks.eq(ind).offset().left;
					var leftOffset = introLinkHorizontalOffset - menuLinkHorizontalOffset - menuLinkLeftPadding;
					$menuLink.css('left', leftOffset);
				});
			};

			/**
			 * Transition stages. Gives the app element classes for
			 * each stage ('navApp-stage0, navApp-stage1, etc);
			 */
			var transitionIn = function(stages) {
				//clear the top property from the menu links
				$menuLinks.css('top','');

				//align the links
				alignFirstLinksVertically();
				alignLinksHorizontally();

				//the running time (previous stages + current)
				var runningTime = 0;

				//have the text re-align if the browser is resized
				isTextAligning = true;

				$.each(stages,function(ind,period){
					runningTime += period;
					setTimeout(function(){
						$app.addClass('navApp-stage'+ind);

						//if the last stage is triggered, clean up
						if ( ind === stages.length - 1) {
							//stop listening to the resize events
							isTextAligning = false;

							//get rid of the links offset
							$menuLinks.css('left','');
						}
					}, runningTime);
				});
			};

			/**
			 * Gets everything ready prepped before the main animation.
			 */
			var initialSetup = function() {
				//align text on load and resize
				$window.on('DOMContentLoaded load resize', alignText);

				//set up the alternate titles
				setUpAltTitles($menuLinks);

				//set up CSS transition staggering
				setUpStaggering($introTextLinks);
				setUpStaggering($introTextLines);
				setUpStaggering($menuLinks);
				setUpStaggering($menuPastDividerLinks, $menuLinks.length-1);

				//pre animations to fade in the text on the homepage
				setTimeout(function(){
					alignFirstLinksVertically();
					$app.addClass('navApp-pre0');
				}, 150);
				setTimeout(function(){
					$app.addClass('navApp-pre1');
				}, 300);
			};

			/**
			 * Triggers the animation from a document click.
			 *
			 * @param stages
			 */
			var listenForDocumentClick = function(stages) {
				//will transition in the text whenever the page is clicked
				$(document).one('click', function(e) {
					if ( head.screen.innerWidth >= SITE.breakpointMobile) {
						e.preventDefault();
						$html.addClass('homepage--clicked');
						transitionIn(stages);
					}
				});
			};

			/**
			 * Triggers the animation from menu interaction.
			 *
			 * @param stages
			 */
			var listenForNavInteraction = function(stages) {
				//will transition in the text whenever the menu is opened
				$.subscribe('mobileMenu.opened', function(){
					setTimeout(function(){
						transitionIn(stages);
					}, 100);
				});
			};

			/**
			 * Kicks off the functionality.
			 */
			var init = function() {
				//is this the homepage?
				var isHomepage = $html.hasClass('homepage');

				//if this is the homepage, keep the text aligned until the transition is done
				//otherwise, this will only be turned on during the transition
				isTextAligning = isHomepage;

				//run the initial setup
				initialSetup();

				//the stage durations, these just add the classes for css to hook into
				//take a look at the components/_navApp.scss for more details
				var homepageStages     = [0, 0, 1000, 1000];
				var nonHomepageStages  = [0, 0, 0, 1000];

				//listen for user interaction that will start the intro transition
				if (isHomepage) {
					listenForDocumentClick(homepageStages);
				} else {
					listenForNavInteraction(nonHomepageStages)
				}
			};

			//initialize the app
			init();
		};

		var apps = $('[data-nav-app]');
		if (apps.length) {
			$(apps).each(function (ind, app) {
				navApp(app);
			});
		}
	},
	/**
	 * Parallax.
	 */
	parallax: function () {
		var $holders = $('[data-parallax-holder]');
		if ( $holders.length ) {
			var $window = $(window);

			$holders.each(function(ind, holder){
				var $holder = $(holder);
				$holder.css({position:'relative'});
				var $item = $holder.find('[data-parallax]').eq(0).css({position:'relative'});
				if ($item.length) {
					var multiplier = $item.attr('data-parallax-multiplier') * -1;
					var attr = $item.attr('data-parallax-attr');
					var mode = $holder.attr('data-parallax-holder') || 'simple';

					$window.on('scroll', function () {
						window.requestAnimationFrame(function () {
							var scrollTop = $window.scrollTop();
							if (mode == 'simple')  {
								$item.css(attr, scrollTop * multiplier);
							} else if (mode == 'top' ) {
								var topPosition = (scrollTop) - ($holder.offset().top);
								$item.css(attr, topPosition * multiplier);
							} else if (mode == 'center') {
								var centeredPosition = (scrollTop + head.screen.innerHeight*.5) - ($holder.offset().top + $holder.outerHeight()*.5);
								$item.css(attr, centeredPosition * multiplier);
							}
						});
					});
				}
			});
		}
	},
	/**
	 * Creates styles to compensate for the scrollbar when the
	 * mobile menu is open.
	 */
	scrollbarCompensation: function () {
		var isIElte8 = (head.browser.ie && head.browser.version < 9);

		if (! isIElte8) {
			//get the scrollbar width
			var scrollbarWidth = SITE.helpers.getScrollbarWidth();

			var style = document.createElement('style');
			style.type = 'text/css';
			style.innerHTML = '.mobileMenuOpen {padding-right: '+scrollbarWidth+'px;}' +
				'.mobileMenuOpen .mobileMenu-toggle {margin-right: '+scrollbarWidth+'px;}' +
				'.mobileMenuOpen .bgBorders {margin-left: '+(scrollbarWidth*-.5)+'px;}';
			document.getElementsByTagName('head')[0].appendChild(style);
		}
	},
	/**
	 * Removes the top padding from consecutive blocks that have the same background color.
	 */
	adjustCustomBackgroundSpacing: function () {
		$('.bg-custom').each(function(ind,el){
			var $el = $(el);
			var $next = $el.next('.bg-custom');
			if ($next.length && ($el.css('background-color') === $next.css('background-color'))) {
				$next.css('padding-top', 0);
			}
		});
	},
	/**
	 * New filters.
	 */
	newFilters: function () {
		var $filter = $("[data-filter]");
		if ($filter.length) {
			var currentFilter = '';
			var pageOffset = 1;

			var $filterResults = $("[data-filter-results]").eq(0);
			var $filterSelect = $("[data-filter-select]").eq(0);
			var $filterButtons = $("[data-filter-button]");

			//more button click listener
			$filterResults.on('click', '[data-filter-more]', function(e) {
				e.preventDefault();

				//get the next page offset
				pageOffset = $(this).attr('data-next-page');

				//remove the filters
				$filterResults.find('[data-filter-removable]').remove();

				//load the items
				loadItems();
			});

			//loads the items
			var loadItems = function() {
				//if the pageOffset is 1, then clear everything out
				if (pageOffset === 1) {
					$filterResults
						.empty()
						.addClass('loading');
				}

				var ajaxData = {};

				var ajaxUrl = '/ajax/new/p'+pageOffset;
				if (!!currentFilter){
					ajaxData.type = currentFilter;
				}

				$.ajax({
					method: 'post',
					url: ajaxUrl,
					data: ajaxData,
					dataType : 'html'
				})
					.success(function(data) {
						$filterResults
							.removeClass('loading')
							.append(data);
					})
					.error(function(jqXHR, textStatus, errorThrown) {
						alert('Error: ' + errorThrown);
					});
			};

			//handle filter changes
			var filterChange = function(val) {
				//set the select
				if ($filterSelect.val() != val) {
					$filterSelect.val(val);
				}

				//set the buttons
				$filterButtons.removeClass('selected');
				$filterButtons.filter('[data-filter-button="'+val+'"]').addClass('selected');

				//load the items if the selected filter changed
				if (currentFilter !== val)
				{
					currentFilter = val;
					pageOffset = 1;
					loadItems();
				}
			};

			//listen for select changes
			$filterSelect.change(function(e){
				e.preventDefault();
				filterChange($filterSelect.val());
			});
			//listen for button clicks
			$filterButtons.click(function(e){
				e.preventDefault();
				filterChange($(this).attr('data-filter-button'));
			});
		}
	},
	/**
	 * Initializes the specified components.
	 */
	init: function () {
		SITE.components.mobileMenu();
		SITE.components.navApp();
		SITE.components.adjustCustomBackgroundSpacing();
		SITE.components.slider();
		SITE.components.smoothScroll();
		SITE.components.lightboxes();
		SITE.components.lightboxesInline();
		SITE.components.responsiveVideos();
		SITE.components.simpleMaps();
		SITE.components.youTubeAutoPlayOnScroll();
		SITE.components.youTubeBackgroundVideo();
		SITE.components.vimeoBackgroundVideo();
		SITE.components.parallax();
		SITE.components.scrollbarCompensation();
		SITE.components.newFilters();
	}
};

//handle dom ready event
$(function domReady() {
	//initialize components
	SITE.components.init();
});